import React from 'react';
import { Link } from 'react-router-dom';

const Card = (props) => {

   

    return (
        <div  className='col-12 col-lg-9 p-5 text-center ' style={{backgroundColor:'#C6AB6D',borderRadius:'15px'}}>
            <div className="row">
                <div className="col-12 col-md-6 ">
                    <img src={props.img} alt="" width={'100%'}/>
                </div>
                <div className="col-md-6 col-12 pt-4">
                    
                    <h1 style={{
                        fontWeight:"bold",
                        fontSize:'30px',
                        color:'#303330'
                    }}>{props.title}</h1>
                    <h6 style={{
                        fontWeight:"normal",
                        fontSize:'18px',
                        direction:props.lang==='ar'?'rtl':'ltr',

                    }}>{props.content}</h6>
                     </div>
            </div>
        </div>
    );
}

export default Card;
