import React from 'react';
import {MyContext} from '../../App'



const Prosses = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0)
        }, [])

    const {lang} = React.useContext(MyContext)
    const content = lang === 'ar' ? data.ar : data.en
    return (
        <div className='m-5'
        dir={lang==='en'?'ltr':'rtl'}
        >
            <h2 style={{color:'#AD9357'}}>{content.h}</h2>
            <h3>{content.h2}</h3>
            <div className="row justify-content-center gap-3 ">
                <ul>
                {
                    content.list.map(e=>(
                        <li className='p-2'><span style={{fontWeight:'bold'}}>{e.h}</span><p>{e.p}</p></li>
                        
                    ))
                }
                </ul>

            </div>
            
        </div>
    );
}

export default Prosses;





const en ={h:'Dar Abraj Baghdad offers a comprehensive range of services in the contracting sector. ',
h2:'Here is an overview of some of these services:',
list: [
    {
        h:`Planning and Design`,
        p:`The company provides planning and design services for various projects. This includes site study, engineering analysis, and preparation of engineering designs and necessary blueprints for project execution.`
    },
    {
        h:`Infrastructure`,
        p:`The company specializes in infrastructure projects, such as road construction, bridges, utilities, sewage, and water systems. These projects are executed using advanced technologies and materials to ensure quality and durability.`
    },
    {
        h:`Industrial Projects`,
        p:`The company offers contracting services for large industrial projects, including construction of factories and other industrial facilities. These projects are meticulously executed in accordance with global industrial standards.`
    },
    {
        h:`Commercial and Residential Projects`,
        p:`The company works on diverse commercial and residential projects, ranging from residential complexes to commercial buildings, hotels, and retail spaces.`
    },
    {
        h:`Maintenance and Operation`,
        p:`The company provides maintenance and operation services for the projects it has executed, ensuring their sustainability and smooth operation over the long term.`
    },
    {
        h:`Engineering Consultation`,
        p:`The company offers engineering consultation services to clients, assisting them in project planning, design, and identifying the best technical solutions.`
    },
    {
        h:`Innovation and Research & Development`,
        p:`Darat Abraj Baghdad places great importance on innovation and research and development in the contracting field. They strive to use modern technologies and innovative materials to improve project efficiency and quality.`
    },
    {
        h:`Security and Safety`,
        p:`The company considers the safety of workers and the surrounding community a top priority. They ensure the implementation .`
    },

]}


const ar ={h:`شركة دار ابراج بغداد تقدم مجموعة شاملة من الخدمات في قطاع المقاولات 

`,
h2:`فيما يلي نظرة عامة على بعض هذه الخدمات`,
list: [
    {
        h:`التخطيط والتصميم`,
        p:`تقدم الشركة خدمات التخطيط والتصميم لمختلف المشاريع. ويشمل ذلك دراسة الموقع والتحليل الهندسي وإعداد التصاميم الهندسية والمخططات اللازمة لتنفيذ المشروع.`
    },
    {
        h:`البنية التحتية`,
        p:`تختص الشركة بمشاريع البنية التحتية، مثل بناء الطرق والجسور والمرافق والصرف الصحي وأنظمة المياه. وتنفذ هذه المشاريع باستخدام التقنيات والمواد المتطورة لضمان الجودة والمتانة.`
    },
    {
        h:`مشاريع صناعية`,
        p:`تقدم الشركة خدمات المقاولات للمشاريع الصناعية الكبيرة، بما في ذلك بناء المصانع وغيرها من المرافق الصناعية. وتنفذ هذه المشاريع بدقة وفقًا للمعايير الصناعية العالمية.`

    },
    {
        h:`مشاريع تجارية وسكنية`,
        p:`تعمل الشركة على مشاريع تجارية وسكنية متنوعة، تتراوح بين المجمعات السكنية والمباني التجارية والفنادق والمساحات التجارية.`
    },
    {
        h:`الصيانة والتشغيل`,
        p:`تقدم الشركة خدمات الصيانة والتشغيل للمشاريع التي نفذتها، مع ضمان استدامتها وتشغيلها بسلاسة على المدى الطويل.`
    },
    {
        h:`الاستشارات الهندسية`,
        p:`تقدم الشركة خدمات الاستشارات الهندسية للعملاء، مساعدتهم في تخطيط المشروع وتصميمه وتحديد أفضل الحلول التقنية.`
    },
    {
        h:`الابتكار والبحث والتطوير`,
        p:`تولي دار أبراج بغداد أهمية كبيرة للابتكار والبحث والتطوير في مجال المقاولات. وتسعى لاستخدام التقنيات الحديثة والمواد المبتكرة لتحسين كفاءة المشروع وجودته.`
    },
    {
        h:`الأمن والسلامة`,
        p:`تعتبر الشركة سلامة العمال والمجتمع المحيط أولوية قصوى. وتضمن تنفيذ المشاريع بما يتوافق مع أعلى معايير السلامة والأمن.`
    },

]
}

export const data = {en,ar}


