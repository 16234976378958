import React from 'react';

import logo from '../../loader.svg'

const Loder = () => {
    return (
        <div style={{
            width:'100%',
            height:window.innerHeight,
            backgroundColor:'#fff',
            
            
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
           
            
            
        }}>

            <div className=" text-primary row text-center"  style={{
                position:'absolute',
                
            }}>
                
                <img src={logo} alt="" className='anmation-loader'  />
            </div>
            
        </div>
    );
}

export default Loder;
