import React from 'react';
import { MyContext } from '../../../App'
import { Link } from 'react-router-dom';

const Support = () => {
    const { lang } = React.useContext(MyContext)
    const content = lang === 'ar' ? data.ar : data.en
    return (
        <div>
            <div className="row pt-5 pb-5 mt-5 justify-content-center"
                style={{ backgroundColor: '#303330', borderRadius: '15px' }}
            >
                <div className="col-12 col-md-9 text-center">
                    <h3 style={{ color: '#AD9357' }}>{content.h}</h3>
                </div>


                <div className="row justify-content-center gap-3 m-5 ">
                    {
                        content.list.map(e => (
                            <div className="col-12 col-md-6 col-xl-3  p-2 support-card"
                                style={{

                                    height: '70px',
                                    width: '250px',

                                    border: 'solid 1px #AD9357',
                                    color: '#AD9357',


                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    borderRadius: '40px',

                                }}
                            >
                                <h5 >{e}</h5>
                            </div>
                        ))
                    }
                </div>
                <div className="col-12 col-md-4  ">

                    <Link to='/support' >
                        <button className='btn btn-warning' style={{
                            width: '100%',
                            borderRadius: '15px',
                            color: '#303330',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            backgroundColor: '#AD9357',
                            border: 'none',
                            outline: 'none',

                        }}>Read more</button>
                    </Link>

                </div>
            </div>
        </div>
    );
}

export default Support;


const en = {
    h: `Dar abraj baghdad contracting services overview`,
    list: [
        `Planning and Design`,
        `Infrastructure`,
        `Industrial Projects`,
        `Commercial and Residential Projects`,
        `Maintenance and Operation`,
        `Engineering Consultation`,
        `Innovation and Research & Development`,
        `Security and Safety`,
    ]
}

const ar = {
    h: 'تقدم دار أبراج بغداد مجموعة شاملة من الخدمات في قطاع المقاولات. فيما يلي نظرة عامة على بعض هذه الخدمات',
    list: [
        `التخطيط والتصميم`,
        `البنية التحتية`,
        `مشاريع صناعية`,
        `مشاريع تجارية وسكنية`,
        `الصيانة والتشغيل`,
        `الاستشارات الهندسية`,
        `الابتكار والبحث والتطوير`,
        `الأمن والسلامة`,
    ]
}

export const data = { en, ar }