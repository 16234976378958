import React from 'react';
import { MyContext } from '../../../App'
import { Link } from 'react-router-dom';

const Support = () => {
    const { lang } = React.useContext(MyContext)
    const content = lang === 'ar' ? data.ar : data.en
    return (
        <div id='project'>
            <div className="row pt-5 pb-5 mt-5 justify-content-center"
                style={{ backgroundColor: '#AD9357', borderRadius: '15px' }}
            >
                <div className="col-12 col-md-9 text-center">
                    <h3 style={{ color: '#303330' }}>
                        {
                            lang === 'en' ? 'Our Projects' : 'مشاريعنا'
                        }
                    </h3>
                </div>


                <div className="row justify-content-center gap-3 m-5 ">
                    {
                        content.map(e => (
                            <div className="col-12 col-md-6 col-xl-3  p-2 support-card2"
                                style={{

                                    height: '70px',
                                    width: '250px',

                                    border: 'solid 2px #303330',
                                    color: '#303330',


                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    borderRadius: '40px',

                                }}
                            >
                                <h5 >{e}</h5>
                            </div>
                        ))
                    }
                </div>
               
            </div>
        </div>
    );
}

export default Support;


const en = [
   `Al Fahd Factory    `,
   `Solitaire factory 
   `,
   `HQ of Al-Fahd Company
   `,
   `HQ of Dalia Al-Khair Company`,
   `HQ of Al-Fahd Company and Dar Tema`,
   `Shanasheel Mall, Baghdad
   `,
   `Marina Tourist Complex
   `,
   `Baghdad Island Park
   `,
   `Al-Fahd Medical Complex
   `,
   `Marina Private Hospital`,
]


const ar = [
    `مصنع الفهد`,
    `مصنع سولتير`,
    `مقر شركة الفهد`,
    `مقر شركة داليا الخير`,
    `مقر شركة الفهد ودار طعمة`,
    `مول شناشيل بغداد`,
    ` مجمع مارينا السياحي`,
    `جزء من جزيرة بغداد السياحية`,
    `مجمع الفهد الطبي`,
    `مستشفى مارينا الاهلي`,
]


export const data = {en,ar}