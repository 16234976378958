import React from 'react';
import { MyContext } from '../../../App'
import loc from '../../footer/loc.svg'
import phone from '../../footer/phon.svg'
import email from '../../footer/email.svg'


const Contact = () => {
    const { lang } = React.useContext(MyContext)
    const content = lang === 'ar' ? data.ar : data.en


    return (
        <div id='contact' className='continer text-center justify-content-center ' style={{
            borderRadius: '15px',
            border: '1px solid #AD9357',
            padding: '50px 0px',
        }}>
            <h1 style={{ color: '#AD9357' }}>{content.h}</h1>
            <div className="d-flex justify-content-center">
                <p className='col-lg-7 col-11'>{content.p}</p>
            </div>


            <div className="row justify-content-center" >


                <div className="col-12 col-md-3 mt-3" >
                    <img src={loc} alt="" width={50} />
                    <p>Iraq-Baghdad</p>
                </div>

                <div className="col-12 col-md-3 mt-3">
                    <a href="tel:+9647844440088" style={{ textDecoration: 'none', color: '#000', }}>
                        <img src={phone} alt="" width={50} />
                        <p>+9647844440088</p></a>
                </div>

                <div className="col-12 col-md-3 mt-3">
                    <a href="mailto:info@dar-abrajbaghdad.com" style={{ textDecoration: 'none', color: '#000', }}>
                    <img src={email} alt="" className='' width={50} />
                    <p>info@dar-abrajbaghdad.com</p></a>
                </div>

            </div>




        </div>
    );
}

export default Contact;


const en = {
    h: 'Contact Us',
    p: `In need of excellent services in contracting, construction, and related services, or if you are looking for good solutions in the oil sector, we are here to assist you. Get in touch with Dar Abraj Baghdad team today for further consultations regarding our services.`
}

const ar = {
    h: 'اتصل بنا',
    p: `إذا كنت بحاجة إلى خدمات متميزة في المقاولات والبناء والإنشاءات ، أو إذا كنت تبحث عن حلول جيدة في قطاع النفط ، فنحن هنا لمساعدتك. تواصل مع فريق دار أبراج بغداد اليوم للحصول على مزيد من الاستشارات بشأن خدماتنا`
}


export const data = { ar, en }


