import React from 'react';
import {MyContext} from '../../../App'
import img1 from './img/1.png'
import img2 from './img/2.png'

import Card from './card';



const Cards = () => {
    const {lang} = React.useContext(MyContext)
    const content = lang === 'ar' ? data.ar : data.en
    const img = [img1,img2]
    const temp = lang === 'ar' ? 1 : 0
    return (
        <div className='continer'  >


            {
                content.map((e,i)=>(
                    <div className="row mt-5" 
                    style={{
                    
                        direction: (i+temp)%2 === 1 ? 'rtl' : 'ltr',
                    }}
                    >
                        <Card img={img[i]} content={e.p} title={e.title} index={i} lang={lang}/>
                    </div>
                ))

            }
       
        </div>
    );
}

export default Cards;


const en = [
    {
        p:`Dar Abraj Baghdad aims to be a leader in the field of general contracting and oil services, and to be a trusted partner for companies operating in these industries. The company strives to achieve sustainability and success in both local and regional markets, delivering outstanding performance that meets the aspirations of its customers and contributes to improving infrastructure and developing the oil sector.`,
         title:'Company Vision'
    },
  
    {
        p:`Dar Abraj Baghdad 's mission is to provide exceptional services in the field of general contracting and the oil and gas sector with a high level of quality, efficiency, and professionalism. The company aims to effectively meet the needs of its customers and contribute to the development of infrastructure and oil projects in the region. Operating with high ethical standards, the company is committed to environmental and safety standards to achieve sustainable development and social responsibility .`,
         title:'Company Mission'
    },
  
]

const ar = [
    {
        p:`تهدف دار أبراج بغداد إلى أن تكون رائدة في مجال المقاولات العامة وخدمات النفط ، وأن تكون شريكًا موثوقًا للشركات العاملة في هذه الصناعات. تسعى الشركة إلى تحقيق الاستدامة والنجاح في الأسواق المحلية والإقليمية على حد سواء ، وتقديم أداء متميز يلبي تطلعات عملائها ويساهم في تحسين البنية التحتية وتطوير قطاع النفط.`,
            title:'رؤية الشركة'
    },

    {
        p:`
        مهمة دار أبراج بغداد هي تقديم خدمات استثنائية في مجال المقاولات العامة وقطاع النفط والغاز بمستوى عالٍ من الجودة والكفاءة والاحترافية. تهدف الشركة إلى تلبية احتياجات عملائها بفعالية والمساهمة في تطوير البنية التحتية ومشاريع النفط في المنطقة. تلتزم الشركة بمعايير أخلاقية عالية ، وبمعايير البيئة والسلامة لتحقيق التنمية المستدامة والمسؤولية الاجتماعية
        .`,
            title:'مهمة الشركة'
    },

]





export const data = {en,ar}