import React from 'react';
import { MyContext } from '../../../App'
import img1 from './img/1.png'
import img2 from './img/2.png'
import img3 from './img/3.png'



const Our = () => {
    const { lang } = React.useContext(MyContext)
    const content = lang === 'ar' ? data.ar : data.en
    const img = [img1, img2, img3]
    const [show, setshow] = React.useState(false)

    const [text1, settext1] = React.useState(content.list[0].p)
    const [text2, settext2] = React.useState(content.list[1].p)
    const [text3, settext3] = React.useState(content.list[2].p)
    const text = [text1, text2, text3]

    React.useEffect(() => {
        if (show) {
            settext1(content.list[0].p)
            settext2(content.list[1].p)
            settext3(content.list[2].p)
        }
        else {
            settext1('')
            settext2('')
            settext3('')
        }
    }, [show])

    const handelshow = () => {
        setshow(!show)
   
        
    }





    return (

        <div className='continer p-5 m-4 mt-0  mb-0 ' style={{ backgroundColor: '#303330', color: '#AD9357' }}>
            <div className="row">

                <h1 className=" text-center">{content.h}</h1>
            </div>

            <div className="row" >
                {
                    content.list.map((e, i) => (
                        <div key={i} className="col-12 col-md-4 p-4" style={{ translate: 'all 3s ease-in-out' }}    >
                            <span  >
                                <img src={img[i]} alt="" className='col-12' />


                            </span>
                            <h5 className="text-center mt-2" style={{ color: '#fff' }}>{e.h}</h5>
                            <div className='col-12'>
                                <p className='px-2 text-center anmation-1'

                                dir={lang === 'en' ? 'ltr' : 'rtl'}

                                    

                                >{text[1]}</p>
                            </div>

                        </div>
                    ))

                }
            </div>

            <div className='row justify-content-center'>
                <button
                    onClick={handelshow}
                    className='btn btn-light m-2 col-9 col-md-3' style={{ borderRadius: '15px' }}>
                    {show ? 'Hide' : 'Show More'}
                </button>

            </div>



        </div>

    );
}

export default Our;


const en = {
    h: 'The goals of the company',



    list: [
        {
            h: `Achieving Excellence`,
            p: 'Dar Abraj Baghdad aims to achieve excellence in all aspects of its operations, whether in the general contracting sector or in providing oil services. The company strives to be a leading and distinguished entity in its industry'
        },
        {
            h: `Providing High-Quality Services`,
            p: 'The company focuses on delivering high-quality services to its customers, emphasizing seriousness, commitment to deadlines, and meeting customer expectations with the highest levels of satisfaction.'
        },
        {
            h: `Achieving Growth and Expansion`,
            p: ' Dar Abraj Baghdad seeks to achieve sustainable growth and expansion in its business scope and capitalize on emerging opportunities in the construction, oil, and gas industries'
        }

    ]



}


const ar = {
    h: 'أهداف الشركة',

    list: [
        {
            h: `تحقيق التميز`,
            p: 'تهدف دار أبراج بغداد إلى تحقيق التميز في جميع جوانب عملياتها ، سواء في قطاع المقاولات العامة أو في تقديم الخدمات النفطية. تسعى الشركة إلى أن تكون كيانًا رائدًا ومتميزًا في صناعتها'
        },
        {
            h: `تقديم خدمات عالية الجودة`,
            p: 'تركز الشركة على تقديم خدمات عالية الجودة لعملائها ، مؤكدة على الجدية والالتزام بالمواعيد النهائية وتلبية توقعات العملاء بأعلى مستويات الرضا.'
        },
        {
            h: `تحقيق النمو والتوسع`,
            p: 'تسعى دار أبراج بغداد إلى تحقيق النمو والتوسع المستدام في نطاق أعمالها والاستفادة من الفرص الناشئة في صناعة البناء والنفط والغاز'
        }

    ]

}


export const data = { ar, en }
