import React from 'react';
import { MyContext } from '../../../App'
import img1 from './1.jpg'
import { Link } from 'react-router-dom';

const Services = () => {
    const { lang } = React.useContext(MyContext)

    const text = lang === 'ar' ? `
    شركة دار ابراج بغداد تقدم مجموعة واسعة من الخدمات النفطية المتخصصة التي تغطي جميع جوانب صناعة النفط والغاز.  
    ` : `
    Dar Abraj Baghdad offers a wide range of specialized oil services that cover all aspects of the oil and gas industry.
    `
    return (
        <div className='continer' dir={lang === 'en' ? 'ltr' : 'rtl'}>
            <div className="row p-3" style={{ borderRadius: '15px', backgroundColor: '#D9D9D9' }}>
                <div className="col-12 col-md-6">
                    <img src={img1} className='col-12' alt="" />
                </div>
                <div className="col-12 col-md-6" style={{
                    fontSize: '25px',
                    padding: '40px',
                    
                }}>{text}
                    <br />
                
                
                <Link to='/services'>
                        <button
                            style={{
                                backgroundColor: '#AD9357',
                                color: '#fff',
                                borderRadius: '15px',
                                border: 'none',
                                padding: '10px 20px',
                                fontSize: '20px',
                                outline: 'none',
                                margin: '30px 0',

                            }}
                        >
                            Read more
                        </button>
                    </Link>
                </div>

              
            </div>
        </div>
    );
}

export default Services;


