import React, { Component } from "react";
import Slider from "react-slick";
import img1 from './img/1.png'
import img2 from './img/2.png'
import img3 from './img/3.png'
import img4 from './img/4.png'

export default class SimpleSlider extends Component {
  render() {
    const settings = {
        dots: false,
     
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed:3000,
        pauseOnHover:false,
    };
    return (
      <div
      style={{
        transform: 'translateX(3%)',
      }}
      >
       
        <Slider {...settings}  arrows={0}>
        
          {
                [img1,img2,img3,img4].map((item,index)=>{
                    return(
                        <div key={index} className="row">
                            <img src={item} alt="" className="text-center" />
                        </div>
                    )
                }
                )
          }
    
        </Slider>
      </div>
    );
  }
}