import React, { useEffect } from 'react';
import Slider from './slider/slider';
import Intro from './intro/introslid';
import { MyContext } from '../../App';
import Our from './our/our';
import Prosses from './prosses/prosses';
import Support from './support/support';
import Cards from './cards/cards';
import Services from './services/services';
import Contact from './contact/contact';
import { Element } from 'react-scroll';

import Table from './table/table';

const Home = () => {
    const {lang} = React.useContext(MyContext);

    useEffect(() => {
        window.scrollTo(0, 0)
        }, [])
  
    return (
        <div className='container-xl' style={{width:'100%',padding:'0'}} >
          
            <Slider/>
            <br /><br /><br />
            <Intro lang={lang}/>
            <br /><br /><br />
            

            <Our />
            
            <br /><br /><br />
             <Cards />
             <br /><br /><br />
            <Prosses />
            <br /><br /><br />
            <h1 className="text-center">our sevices</h1>
            <Support />
            <br /><br /><br />

            
            <br />
            <Services />
            <br /><br /><br />
            <Table />
            

            



            <br /><br /><br />
            <Contact />
            
            <br /><br /><br />
           


            
            
       
        </div>
    );
}

export default Home;
