import React from 'react';
import { MyContext } from '../../../App'
import img1 from './1.png'

const Introslid = () => {
  const { lang } = React.useContext(MyContext)
  const content = lang === 'ar' ? data.ar : data.en
  return (
    <div id='about'  className='continer p-4 pt-0 pb-0' dir={lang==='en'?'ltr':'rtl'} > 
    
    <h1 className='text-center' style={{color:'#AD9357'}}>{content.h}</h1>

      <div className="row">
        <div className="col-12 col-lg-6 p-4">
          <img src={img1} className='col-12' alt="" />
        </div>
        <div className="col-12 col-lg-6 p-4">
         
          {
            content.p.map((e, i) => (
              <p style={{fontSize:'20px'}} key={i}>{e}</p>
            ))
          }
        </div>
      </div>

    </div>
  );
}

export default Introslid;


const en = {
  h:'About Us',
  p:[
    `Dar Abraj Baghdad" is a leading and specialized company in the field of contracting, construction, and infrastructure in Iraq. The company was founded with an ambitious vision to meet the market's needs and contribute to the development of infrastructure and oil projects in the region. We have an outstanding team with the necessary experience and skills to achieve excellence in every project we undertake.`,
    `Since our establishment, we have been committed to achieving quality and innovation in all aspects of our work. We provide comprehensive services, including major infrastructure projects, commercial and industrial buildings, as well as various oil and gas projects.    `
  ]
}


const ar = {
  h:'من نحن',
  p:[
    `شركة "دار أبراج بغداد" هي شركة رائدة ومتخصصة في مجال المقاولات والإنشاءات والبنية التحتية في العراق. تأسست الشركة برؤية طموحة لتلبية احتياجات السوق والمساهمة في تطوير البنية التحتية ومشاريع النفط في المنطقة. لدينا فريق متميز بالخبرة والمهارات اللازمة لتحقيق التميز في كل مشروع نقوم به`,
    `منذ تأسيسنا ، التزمنا بتحقيق الجودة والابتكار في جميع جوانب عملنا. نقدم خدمات شاملة ، بما في ذلك مشاريع البنية التحتية الرئيسية والمباني التجارية والصناعية ، بالإضافة إلى مشاريع النفط والغاز المختلفة.`
  ]
}


export const data = {ar,en}