
import React,{useState} from 'react'
import './nav.css';
import {MyContext} from '../../App';
import logo from './logo.svg';


import { NavLink,Link, useNavigate} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';






export default function Nav() {

    const context = React.useContext(MyContext);



    
   const [dropdownclass,setdropdown] = useState('close');
   


    const handldrop =(e)=>{
        e.preventDefault();
        if(dropdownclass === "close")
        setdropdown("open");
        else
        setdropdown('close')
    }

    const arr = context.lang === 'en' ? ['Home','About Us','Our Services','Our Project','Contact Us'] : ['الرئيسية','من نحن','خدماتنا','مشاريعنا','تواصل معنا']

    const arrser = context.lang === 'en' ? ['contracting services','oil and gas','Additional Services'] : ['خدمات المقاولات','النفط والغاز','خدمات اضافية']
    const navegate = useNavigate();

    const handlser = (e)=>{
        e.preventDefault();
        
        if(e.target.value === '0')
            navegate('/services/contracting')
        else if(e.target.value === '1')
            navegate('/services/oil')
        else if(e.target.value === '2')
            navegate('/services/additional')

        setdropdown('close')
    }


    return (
        <div className='div-con' style={{
            
           
        }}>
            <nav className='' dir={context.lang === 'en'? 'ltr' :'rtl'}>
                <div>
                    
               <Link to={"/"} className='m-2 link-nav' > <img src={logo} alt="" width={120}/></Link>
                   
                    
                 
                 
                </div>

                <div className='nav_link larg-screen ' dir='' style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}>
                   
                <NavLink onClick={()=>{setdropdown('close')}} activeclassname="active" to={"/"} className='m-5 link-nav' >{arr[0]}</NavLink>  
                <HashLink onClick={()=>{setdropdown('close')}} activeclassname="active" to={"/#about"} className='m-5 link-nav' >{arr[1]} </HashLink> 


  
                    
                    

                    <select  className='link-nav select-ser' value={arr[2]} 
                    onChange={(e)=>{handlser(e)}}
                    style={{
                        backgroundColor: 'none',
                        border: 'none',
                        fontWeight: '500',
                        appearance: 'none',
                        padding: '5px',
                        
                    }  }>
                        <option hidden>{arr[2]}</option>
                        <option  value={0}>
                            {arrser[0]}
                        </option>
                        <option value={1}>
                           {arrser[1]}
                        </option>
                        <option value={2} >
                            {arrser[2]}
                        </option>
                    </select>
                    
                  


                <HashLink onClick={()=>{setdropdown('close')}} activeclassname="active" to={"/#project"} className='m-5 link-nav' > {arr[3]}</HashLink>
                <HashLink onClick={()=>{setdropdown('close')}} activeclassname="active" to={"/#contact"} className='m-5 link-nav' > {arr[4]}</HashLink>
                
                </div>

                <div className="medlle-screen" style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    position:'apsoolute',
                    right:'0',
                  
                }}>
                  <div className={dropdownclass} onClick={handldrop}
                 
                  >
                     <div className='dropdown dropdown1'></div>
                      <div className='dropdown dropdown2'></div>
                      <div className='dropdown dropdown1'></div>
                  </div>
                
                 {(dropdownclass === "open") && <ul style={{zIndex:1000}}>
                <li><NavLink onClick={()=>{setdropdown('close')}} activeclassname="active" to={"/"} className='m-3 link-nav' >{arr[0]}</NavLink>  </li>
                <li><HashLink onClick={()=>{setdropdown('close')}} activeclassname="active" to={"/#about"} className='m-5 link-nav' >{arr[1]} </HashLink> </li>
                <li><select  className='link-nav select-ser ' value={arr[2]} 
                    onChange={(e)=>{handlser(e)}}
                    style={{
                        backgroundColor: '#ad935700',
                        border: 'none',
                        fontWeight: '500',
                        appearance: 'none',
                        transform: 'translatex(25px)',
                       
                        
                    }  }>
                        <option hidden>{arr[2]}</option>
                        <option  value={0}>
                            {arrser[0]}
                        </option>
                        <option value={1}>
                           {arrser[1]}
                        </option>
                        <option value={2} >
                            {arrser[2]}
                        </option>
                    </select> </li>
                <li> <HashLink onClick={()=>{setdropdown('close')}} activeclassname="active" to={"/#project"} className='m-3 link-nav' > {arr[3]}</HashLink></li>
                <li>   <HashLink onClick={()=>{setdropdown('close')}} activeclassname="active" to={"/#contact"} className='m-3 link-nav' > {arr[4]}</HashLink>
                </li>

                <li>
                <div className=''>
                <select defaultValue={context.lang} name="" id="" style={{
                    backgroundColor: 'rgb(255, 255, 255,0)',
                    outline: 'none',
                    padding: '5px',
                    borderRadius: '5px',
                   
                }}
                onChange={(e)=>{
                    context.setLang(e.target.value)
                }}
                
                >
                    <option value="en">English</option>
                    <option value="ar">Arabic</option>

                </select>
            </div>
                </li>

            </ul>}</div>

            <div className='lang-xl'>
                <select  name="" id="" style={{
                    backgroundColor: 'rgb(255, 255, 255,0)',
                    
                    outline: 'none',
                    padding: '5px',
                    borderRadius: '5px',
                   
                }}
                onChange={(e)=>{
                    context.setLang(e.target.value)
                }}
                
                >
                    <option value="en">English</option>
                    <option value="ar">Arabic</option>

                </select>
            </div>
            </nav>


           
        </div>
    )
}
