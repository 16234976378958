import React from 'react';
import logo from '../../components/nav/logo.svg'
import loc from './loc.svg'
import phone from './phon.svg'
import email from './email.svg'
import { Link } from 'react-router-dom';
import { MyContext } from '../../App';

const Footer = () => {
    const context = React.useContext(MyContext);

   
    return (
        <div className='continer con'
        
        
        >
            <div className="row p-3 m-2" style={{backgroundColor:'#C6AB6D',borderRadius:'15px',border:'solid 2px '}}>
               
               
               <div className="col-12 col-md-3  ">
                <Link to='/' >
                    <img src={logo} alt="" width={40} style={{
                        transform:'scale(2.3)'
                        
                    }}
                    />
               
                </Link>
                </div>
                <div className="col-12 col-md-2 mt-3">
                    <img src={loc} alt=""  />
                    <p>Iraq-Baghdad</p>
                </div>
                <div className="col-12 col-md-2 mt-3">
                <a href="tel:+9647844440088" style={{ textDecoration: 'none', color: '#000', }}>
                <img src={phone} alt="" />
                    <p>+9647844440088</p>
                    </a>
                </div>
                <div className="col-12 col-md-3 mt-3">
                <a href="mailto:info@dar-abrajbaghdad.com" style={{ textDecoration: 'none', color: '#000', }}>
                <img src={email} alt="" />
                    <p>info@dar-abrajbaghdad.com</p>
                    </a>
                </div>


                <div className='col-2 mt-2 p3-2'>
                    <select name="" id=""
                    style={{
                        width:'80px',
                        height:'30px',
                        borderRadius:'5px',
                        border:'none',
                        outline:'none',
                        backgroundColor:'#C6AB6D',
                        

                    }}

                    onChange={(e)=>{
                        context.setLang(e.target.value)
                    }}
                    >
                        <option value="en">English</option>
                        <option value="ar">عربي</option>
                    </select>
                </div>

            </div>

            
        </div>
    );
}

export default Footer;
