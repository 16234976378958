import React from 'react';
import { MyContext } from '../../App'

const Services = () => {
    const { lang } = React.useContext(MyContext)
    const content = lang === 'ar' ? data.ar : data.en


    React.useEffect(() => {
        window.scrollTo(0, 0)
        }, [])
    return (
        <div dir={lang === 'en' ? 'ltr' : 'rtl'} className='m-4' >

            <h2 className='mb-4' style={{color:'#AD9357'}}>{content.h}</h2>
            <h3>{content.h2}</h3>
            <ol>
                {
                    content.list.map(e => (<>
                        <li><h5 style={{
                            fontWeight: 'bold',
                            color: '#AD9357'
                        }}>{e.h}</h5><p>{e.p}</p></li> </>
                    ))
                }
            </ol>

            <br /><br />

           
           
        </div>
    );
}

export default Services;



const ar = {
    h: `    دار ابراج بغداد تقدم مجموعة واسعة من الخدمات النفطية المتخصصة التي تغطي جميع جوانب صناعة النفط والغاز. `,
    h2: `فيما يلي بعض أنواع خدماتها النفطية:`,
    list: [
        {
            h: `خدمات الحقول النفطية`,
            p: `تقدم الشركة خدمات شاملة لإدارة وتشغيل الحقول النفطية، بما في ذلك الصيانة والتشغيل وإدارة الإنتاج.`
        },
        {
            h: `الهندسة والاستشارات`,
            p: `توفر الشركة خدمات هندسية واستشارات متخصصة للشركات النفطية، وذلك لتحليل وتصميم المشاريع النفطية وتوفير الحلول الهندسية الأمثل.`
        },
        {
            h: `توريد المعدات والمواد`,
            p: `تقدم الشركة خدمات توريد المعدات والمواد اللازمة لعمليات النفط، مما يساعد الشركات على الحصول على الموارد والمعدات بكفاءة وبأسعار مناسبة.`
        },
        {
            h: `. الصيانة والدعم الفني`,
            p: `تهتم دار ابراج بغداد بتقديم خدمات الصيانة والدعم الفني للشركات النفطية، لضمان سلامة واستمرارية العمليات.`
        },
        {
            h: `التواصل والعلاقات العامة`,
            p: `تعمل دار ابراج بغداد على تسهيل التواصل بين الشركات النفطية والجهات الحكومية والمجتمعات المحلية، وتبني علاقات عامة إيجابية ومثمرة.`
        },
        {
            h: `الدراسات البيئية والاستدامة`,
            p: `تقدم الشركة خدمات دراسات التأثير البيئي والاستدامة للمشاريع النفطية. تساعد هذه الدراسات الشركات على التعامل بفعالية مع الجوانب البيئية والمسائل المتعلقة بالاستدامة، والتأكد من الامتثال للمعايير البيئية الصارمة.`
        },
        {
            h: `الدعم اللوجستي`,
            p: `تقدم الشركة الدعم اللوجستي للشركات النفطية لتسهيل عملياتها وتنظيم الإمدادات والتوزيع بفاعلية. تساعد عملية اللوجستيات على تحقيق كفاءة أعلى وتقليل التكاليف.`
        },
        {
            h: `الدعم الهندسي في المشاريع النفطية`,
            p: `تقدم الشركة دعمًا هندسيًا متكاملاً للشركات النفطية، بدءًا من تصميم المشاريع وصولًا إلى مرحلة التنفيذ، لضمان تنفيذ المشاريع بنجاح وبأعلى جودة.`
        }
    ],



    title2: `خدمات إضافية`
    ,

    list2: [
        {
            n: '',
            f: '',
            h: 'خدمات توفير العمالة',
            list: [
                {
                    h: `توفير العمالة المؤقتة`,
                    p: `قد تقدم الشركة خدمات توفير العمالة المؤقتة لتلبية الاحتياجات المؤقتة للشركات في مشاريع معينة. هذا يشمل العمالة غير المتخصصة والعمالة ذات المهارات الخاصة.`
                },
                {
                    h: `توظيف واختيار العمالة`,
                    p: `تقوم الشركة بتوظيف واختيار العمالة المناسبة وفقًا لاحتياجات الشركات العميلة. يتضمن ذلك إجراء المقابلات والتقييمات والتحقق من المؤهلات والمهارات.`
                },
                {
                    h: `التدريب والتأهيل`,
                    p: `يمكن للشركة أن تقوم بتدريب وتأهيل العمالة لتطوير مهاراتها وتحسين أدائها في المشاريع المختلفة.`
                },
                {
                    h: ` إدارة الموظفين`,
                    p: `تقوم الشركة بإدارة العمالة الموفرة للشركات العميلة، بما في ذلك إدارة الحضور والانصراف والرواتب والبدلات والامتيازات.`
                },
                {
                    h: `الالتزام بالمعايير والقوانين`,
                    p: `تضمن الشركة أن العمالة الموفرة تلتزم بجميع المعايير الصحية والأمان والقوانين المحلية المعمول بها.`
                },
            ]
        },
        {
            n: '',
            f: '',
            h: 'خدمات توريد الأطعمة وتوفير المساكن',
            list: [
                {
                    h: `توريد الأطعمة`,
                    p: `تقوم شركة دار ابراج بغداد بتوفير الوجبات الغذائية للعاملين في الحقول النفطية، بما في ذلك وجبات الإفطار والغداء والعشاء والوجبات الخفيفة. يتم تجهيز الأطعمة باستخدام مكونات طازجة وذات جودة عالية لضمان توفير وجبات مغذية وصحية.`
                },
                {
                    h: `توفير المساكن`,
                    p: `توفر الشركة مساكن مريحة وآمنة للعاملين في الحقول النفطية. تحتوي المساكن على وسائل راحة مثل الأسرّة والأثاث الأساسي ووسائل التبريد والتدفئة حسب الحاجة. يتم الاهتمام بنظافة وصيانة المساكن بشكل منتظم لضمان الراحة والسلامة للعاملين.`
                },
                {
                    h: `الخدمات الإضافية`,
                    p: `قد تقدم شركة دار ابراج بغداد بعض الخدمات الإضافية للعاملين في الحقول النفطية، مثل خدمات الترفيه والتسلية، وخدمات النقل الداخلي، وتوفير مرافق للرياضات والترفيه. يهدف ذلك إلى رفع معنويات العاملين وتقديم بيئة عمل مريحة وممتعة.`
                },
                {
                    h: `الأمان والصحة`,
                    p: `تولي الشركة اهتمامًا كبيرًا بالأمان والصحة للعاملين في الحقول النفطية. تطبق جميع الإجراءات اللازمة لضمان سلامة العاملين والالتزام بالمعايير الصحية والأمنية المطلوبة.`
                },
            ]
        },
        {
            n: 'شركة دار ابراج بغداد قد تقدم خدمات كهربائية متميزة وشاملة لمختلف القطاعات، بما في ذلك قطاع المقاولات والبناء وقطاع النفط والغاز. تقدم الشركة الخدمات الكهربائية لتلبية احتياجات العملاء وتحقيق النجاح في المشاريع الكهربائية. من بين الخدمات الكهربائية التي قد تقدمها شركة دار ابراج بغداد:',
            f: 'تتمتع شركة دار ابراج بغداد بفريق متخصص من الخبراء والفنيين في مجال الكهرباء، وتسعى دائمًا لتقديم خدمات متميزة وتلبية تطلعات العملاء في قطاع الكهرباء والطاقة. تهدف الشركة إلى تحقيق الجودة والاستدامة في تنفيذ مشاريعها الكهربائية وتحسين الأداء العام للمشاريع والمنشآت الكهربائية التي تعمل عليه.',
            h: 'خدمات كهربائية',
            list: [
                {
                    h: `. تصميم وتنفيذ المشاريع الكهربائية`,
                    p: `تقوم الشركة بتصميم وتنفيذ مشاريع كهربائية متنوعة، بدءًا من تركيب وصيانة أنظمة الإضاءة والتمديدات الكهربائية في المباني والمنشآت إلى مشاريع أكبر مثل شبكات التوزيع والتحكم الكهربائي في الحقول النفطية.`
                },
                {
                    h: `توريد المعدات واللوازم الكهربائية`,
                    p: `تقوم الشركة بتوريد المعدات واللوازم الكهربائية اللازمة للمشاريع، مثل الأسلاك والكابلات والوحدات والأجهزة الكهربائية الأخرى.`
                },
                {
                    h: `الصيانة والتشغيل`,
                    p: `تقدم دار ابراج بغداد خدمات الصيانة والتشغيل الدورية لأنظمة الكهرباء لضمان استمرارية وكفاءة عملها، بما في ذلك صيانة المحطات الكهربائية والتحكم في الأعطال.`
                },
                {
                    h: `تحسين الكفاءة الطاقوية`,
                    p: `تعمل الشركة على تحسين كفاءة الاستخدام الكهربائي وتطبيق الحلول الطاقوية المستدامة، مما يساهم في تقليل التكاليف والاستهلاك الزائد للطاقة.`
                },
                {
                    h: ` الاستشارات الفنية`,
                    p: `تقدم دار ابراج بغداد الاستشارات الفنية للعملاء فيما يتعلق بالمشاريع الكهربائية وتقديم الحلول المبتكرة والمناسبة لاحتياجاتهم.`
                },
            ]
        }
    ]



}


const en = {
    h: `Dar Abraj Baghdad offers a comprehensive range of specialized oil services that cover all aspects of the oil and gas industry. `,
    h2: `Here are some types of its oil services:`,
    list: [
        {
            h: `Oil Field Services`,
            p: `The company provides comprehensive services for managing and operating oil fields, including maintenance, operation, and production management.`
        },
        {
            h: `Engineering and Consultation`,
            p: `The company provides specialized engineering and consulting services for oil companies, for analyzing and designing oil projects and providing optimal engineering solutions.`
        },
        {
            h: `Equipment and Materials Supply`,
            p: `The company provides equipment and materials supply services for oil operations, helping companies to obtain resources and equipment efficiently and at reasonable prices.`
        },
        {
            h: `Maintenance and Technical Support`,
            p: `Darat Abraj Baghdad is concerned with providing maintenance and technical support services for oil companies, to ensure the safety and continuity of operations.`
        },
        {
            h: `Communication and Public Relations`,
            p: `Darat Abraj Baghdad works to facilitate communication between oil companies, government agencies, and local communities, and to build positive and fruitful public relations.`
        },
        {
            h: `Environmental Studies and Sustainability`,
            p: `The company provides environmental impact studies and sustainability services for oil projects. These studies help companies to effectively deal with environmental aspects and sustainability issues, and to ensure compliance with strict environmental standards.`
        },
        {
            h: `Logistics Support`,
            p: `The company provides logistics support for oil companies to facilitate their operations and organize supplies and distribution effectively. The logistics process helps to achieve higher efficiency and reduce costs.`
        },
        {
            h: `Engineering Support in Oil Projects`,
            p: `The company provides comprehensive engineering support for oil companies, from project design to implementation, to ensure the successful implementation and highest quality of projects.`
        }
    ],
    title2: `Additional Services`,
    list2: [
        {
            n: '',
            f: '',
            h: `Labor Supply Services`,
            list: [
                {
                    h: `Temporary Labor Supply`,
                    p: `The company may provide temporary labor supply services to meet the temporary needs of companies in certain projects. This includes unskilled labor and skilled labor.`
                },
                {
                    h: `Recruitment and Selection of Labor`,
                    p: `The company recruits and selects the appropriate labor according to the needs of the client companies. This includes conducting interviews, assessments, verifying qualifications and skills.`
                },
                {
                    h: `Training and Qualification`,
                    p: `The company can train and qualify labor to develop their skills and improve their performance in various projects.`
                },
                {
                    h: `Employee Management`,
                    p: `The company manages the labor provided to client companies, including attendance, departure, salaries, allowances, and benefits management.`
                },
                {
                    h: `Compliance with Standards and Laws`,
                    p: `The company ensures that the provided labor complies with all health, safety, and local laws and standards.`
                },
            ]
        },
        {
            n: '',
            f: '',
            h: `Food Supply and Housing Services`,
            list: [
                {
                    h: `Food Supply`,
                    p: `Darat Abraj Baghdad provides meals for workers in oil fields, including breakfast, lunch, dinner, and snacks. The food is prepared using fresh and high-quality ingredients to ensure the provision of nutritious and healthy meals.`
                },
                {
                    h: `Housing Supply`,
                    p: `The company provides comfortable and safe housing for workers in oil fields. The housing contains amenities such as beds, basic furniture, cooling and heating facilities as needed. The cleanliness and maintenance of the housing are regularly taken care of to ensure the comfort and safety of the workers.`
                },
                {
                    h: `Additional Services`,
                    p: `Darat Abraj Baghdad may provide some additional services for workers in oil fields, such as entertainment and recreation services, internal transportation services, and providing sports and entertainment facilities. This aims to raise the morale of workers and provide a comfortable and enjoyable working environment.`
                },
                {
                    h: `Safety and Health`,
                    p: `The company pays great attention to the safety and health of workers in oil fields. All necessary procedures are applied to ensure the safety of workers and compliance with the required health and safety standards.`
                },
            ]
        },
        {
            n: `Darat Abraj Baghdad may provide distinguished and comprehensive electrical services for various sectors, including the contracting and construction sector and the oil and gas sector. The company provides electrical services to meet customer needs and achieve success in electrical projects. Among the electrical services that Darat Abraj Baghdad may provide:`,
            f: `Darat Abraj Baghdad has a specialized team of experts and technicians in the field of electricity, and is always striving to provide distinguished services and meet the aspirations of customers in the electricity and energy sector. The company aims to achieve quality and sustainability in the implementation of its electrical projects and improve the overall performance of the projects and facilities it works on.`,
            h: `Electrical Services`,
            list: [
                {
                    h: `Design and Implementation of Electrical Projects`,
                    p: `The company designs and implements various electrical projects, ranging from installing and maintaining lighting systems and electrical installations in buildings and facilities to larger projects such as distribution networks and electrical control in oil fields.`
                },
                {
                    h: `Supply of Electrical Equipment and Supplies`,
                    p: `The company supplies the necessary electrical equipment and supplies for projects, such as wires, cables, units, and other electrical appliances.`
                },
                {
                    h: `Maintenance and Operation`,
                    p: `Darat Abraj Baghdad provides periodic maintenance and operation services for electrical systems to ensure their continuity and efficiency, including maintenance of power stations and fault control.`
                },
                {
                    h: `Energy Efficiency Improvement`,
                    p: `The company works to improve the efficiency of electrical use and apply sustainable energy solutions, which contributes to reducing costs and excessive energy consumption.`
                },
                {
                    h: `Technical Consultations`,
                    p: `Darat Abraj Baghdad provides technical consultations to customers regarding electrical projects and provides innovative and appropriate solutions to their needs.`
                },
            ]
        }
    ]

}

export const data = { ar, en }