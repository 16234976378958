import React from 'react';

import img1 from './img/1.png'
import img2 from './img/2.png'
import img3 from './img/3.png'




const Prosses = () => {
    
    const img = [img1,img2,img3]

    return (
        <div className='continer text-center' >
            

            <div className="row">
                
 <h1 className='text-center m-4' style={{color:'#C6AB6D'}}>Gallery</h1>
                                
                                  
                {
                    img.map((e,i)=>(
                        <div className="col-12 col-md-4 col ">
                            <div className="row justify-content-center" >

                                 <img src={img[i]} alt="" width={'100px'} className=' text-center '
                                    style={{
                                        borderRadius:'15px',
                                    
                                       
                                    }}
                                    />

                                   
                                

                                
                            </div>


                           
                        </div>
                    ))


                }
                
            </div>
          
        </div>
    );
}

export default Prosses;


const en = {
    h:'Gallery',
    p:'The construction process involves three distinct phases',
    list:[
        `pre-construction`,
        `construction`,
        `post-construction`,
    ]
}

const ar = {
    h:'عملية البناء',
    p:'تتضمن عملية البناء ثلاث مراحل متميزة',
    list:[
        `مرحلة ما قبل البناء`,
        `مرحلة البناء`,
        `مرحلة ما بعد البناء`,
    ]
}


export const data = {en,ar}
