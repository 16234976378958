import React from 'react';
import Home from './components/home/home';
import { BrowserRouter, Route,Routes } from 'react-router-dom'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import Nav from './components/nav/nav';
import Footer from './components/footer/footer';

import Page2 from './components/pages/page2';
import Services from './components/pages/services';
import Loader from './components/loader/loder';
import Ser3 from './components/pages/ser3';






Aos.init({
})

export const MyContext = React.createContext();

function App() {

  const [lang, setLang] = React.useState('en')
  const [loader, setLoader] = React.useState(1)


  React.useEffect(() => {
    
    setTimeout(() => {
      setLoader(0)
    }, 4000);
  }, [])




  return (<> {

    
    loader ? <Loader/> :  <MyContext.Provider value={{lang, setLang}}>
    <div className="App">
      <BrowserRouter>
      <Nav/>
        <Routes>
          <Route  path="/" index element={<Home/>} />
        
          <Route  path="/services/contracting"  element={<Page2/>} />
          <Route  path="/services/oil"  element={<Services/>} />
          <Route  path="/services/additional"  element={<Ser3/>} />
          
      


        


        </Routes>
        <Footer/>
  
      </BrowserRouter>



    </div>
    </MyContext.Provider>
  }

    </>
  );
}

export default App;
